
import Vue from "vue";
import IAppImporterOrigin from "@/lib/interfaces/app-importer-origin";
import AppImportersOriginsList from "@/components/subviews/app-importers-origins/app-importers-origins-list.vue";
import AppImportersOriginsMaintenance from "@/components/subviews/app-importers-origins/app-importers-origins-maintenance.vue";

export default Vue.extend({
  name: "AppImportersOrigins",

  components: { AppImportersOriginsList, AppImportersOriginsMaintenance },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      refresh: 0,

      currentTab: 0,

      appImporterOrigin: {} as IAppImporterOrigin,
    };
  },
});
