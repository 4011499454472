
import Api from "@/lib/api";
import { Rq } from "@/lib/amdt";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ACard from "@/components/elements/a-card.vue";
import IAppImporterOrigin from "@/lib/interfaces/app-importer-origin";
import IImportApp from "@/lib/interfaces/import-app";
import IPerson from "@/lib/interfaces/person";
import ICostCenter from "@/lib/interfaces/cost-center";

export default Vue.extend({
  name: "AppImportersOriginsList",

  components: {
    ABtn,
    ACard,
    ADataTable,
    ATextField,
    vAlertMessage,
  },

  props: {
    appImporterOrigin: {
      type: Object as PropType<IAppImporterOrigin>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      pendingConnections: 0,
      ret_id: 1,
      ret_msg: "",

      total: 0,
      search: "",

      options: {} as DataOptions,
      appImportersOrigins: new Array<IAppImporterOrigin>(),
      apps: new Array<IImportApp>(),
      empresas: new Array<IPerson>(),
      costCenters: new Array<ICostCenter>(),

      headers: [
        { text: "Aplicação", value: "cApp", sortable: false },
        { text: "Empresa", value: "cEmpresa", sortable: false },
        { text: "Centro de custo", value: "cCostCenter", sortable: false },
        { text: "Setor", value: "ndatabase", sortable: false },
        { text: "Região", value: "cRegiao", sortable: false },
        { text: "Fechamento", value: "cAutoFechamento", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },

  watch: {
    forceRefresh() {
      this.getAppImportersOrigins().then();
    },

    options: {
      deep: true,
      handler() {
        this.getAppImportersOrigins().then();
      },
    },
  },

  computed: {
    cAppImportersOrigins(): Array<Record<string, unknown>> {
      return this.appImportersOrigins.map((appImporterOrigin) => {
        return {
          ...appImporterOrigin,

          cApp:
            this.apps.find((app) => app.id === appImporterOrigin.id_aplicacao)
              ?.descricao ?? appImporterOrigin.id_aplicacao,

          cEmpresa:
            this.empresas.find((emp) => emp.id === appImporterOrigin.id_pessoa)
              ?.nome_raz ?? appImporterOrigin.id_pessoa,

          cCostCenter:
            this.costCenters.find(
              (costCenter) =>
                costCenter.id === appImporterOrigin.id_centro_custo
            )?.descricao ?? appImporterOrigin.id_centro_custo,

          cRegiao: `${appImporterOrigin.regiao_origem} - ${appImporterOrigin.nregiao}`,

          cAutoFechamento:
            appImporterOrigin.auto_fechamento === "0" ? "Manual" : "Automático",
        };
      });
    },

    loading(): boolean {
      return this.pendingConnections !== 0;
    },
  },

  mounted() {
    this.getApps().then();
    this.getPeople().then();
    this.getCostCenters().then();
  },

  methods: {
    async openAppImporterOrigin(id: string) {
      const appImporterOrigin = this.appImportersOrigins.find(
        (appImporterOrigin) => appImporterOrigin.id === id
      );
      this.$emit("update:app-importer-origin", { ...appImporterOrigin });
    },

    async getAppImportersOrigins() {
      try {
        this.pendingConnections++;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("aplicacao_importador_origem.read", {
          search: this.search,
          desativado: "0",
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.appImportersOrigins =
          rsp.getTable("aplicacao_importador_origem")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.pendingConnections--;
      }
    },

    async getApps() {
      try {
        this.pendingConnections++;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("aplicacao.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.apps = rsp.getTable("aplicacao")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.pendingConnections--;
      }
    },

    async getPeople() {
      try {
        this.pendingConnections++;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.empresas = rsp.getTable("pessoas")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.pendingConnections--;
      }
    },

    async getCostCenters() {
      try {
        this.pendingConnections++;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("centro_de_custo.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.costCenters = rsp.getTable("centro_custo")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.pendingConnections--;
      }
    },
  },
});
