
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { Rq, Table } from "@/lib/amdt";
import { required, maxLength } from "@/lib/validations";
import WorgError from "@/lib/worg-error";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ACard from "@/components/elements/a-card.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ABtn from "@/components/elements/a-btn.vue";
import VForm from "@/lib/types/v-form";
import IAppImporterOrigin from "@/lib/interfaces/app-importer-origin";
import AAutocomplete from "@/components/elements/a-autocomplete.vue";
import IImportApp from "@/lib/interfaces/import-app";
import IPerson from "@/lib/interfaces/person";
import IOrigin from "@/lib/interfaces/origin";
import ICostCenter from "@/lib/interfaces/cost-center";
import ASwitch from "@/components/elements/a-switch.vue";

export default Vue.extend({
  name: "AppImportersOriginsMaintenance",

  components: {
    ABtn,
    ACard,
    ATextField,
    vAlertMessage,
    ABtnDialogConfirm,
    AAutocomplete,
    ASwitch,
  },

  props: {
    appImporterOrigin: {
      type: Object as PropType<IAppImporterOrigin>,
      required: true,
    },
  },

  data() {
    return {
      pendingConnections: 0,

      valid: false,
      ret_id: 1,
      ret_msg: "",

      dataAppImporterOrigin: {
        id_aplicacao: "",
        id_pessoa: "",
        id_centro_custo: "",
        auth_key: "",
        auto_fechamento: "1",
        regioes_origem: new Array<string>(),
      },

      currentRegioesOrigem: new Array<IAppImporterOrigin>(),
      apps: new Array<IImportApp>(),
      empresas: new Array<IPerson>(),
      regioesOrigem: new Array<IOrigin>(),
      costCenters: new Array<ICostCenter>(),
    };
  },

  watch: {
    appImporterOrigin: {
      deep: true,
      handler() {
        this.ret_id = 1;
        this.ret_msg = "";

        this.dataAppImporterOrigin = {
          id_aplicacao: this.appImporterOrigin.id_aplicacao,
          id_pessoa: this.appImporterOrigin.id_pessoa,
          id_centro_custo: this.appImporterOrigin.id_centro_custo,
          auth_key: this.appImporterOrigin.auth_key,
          auto_fechamento: this.appImporterOrigin.auto_fechamento,
          regioes_origem: [],
        };

        this.getAppImportersOrigins().then();
      },
    },

    "dataAppImporterOrigin.id_aplicacao"() {
      this.getOrigins().then();
      this.getAppImportersOrigins().then();
    },
    "dataAppImporterOrigin.id_pessoa"() {
      this.getAppImportersOrigins().then();
    },
    "dataAppImporterOrigin.id_centro_custo"() {
      this.getAppImportersOrigins().then();
    },
  },

  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },

    loading(): boolean {
      return this.pendingConnections !== 0;
    },

    cRegioesOrigem(): Record<string, unknown>[] {
      return this.regioesOrigem.map((item) => ({
        ...item,
        text: `${item.ndatabase}: ${item.empresa_id} - ${item.empresa_nome}`,
      }));
    },
  },

  mounted() {
    this.getApps().then();
    this.getPeople().then();
    this.getCostCenters().then();
  },

  methods: {
    maxLength,
    required,

    async evOnSubmit() {
      try {
        this.pendingConnections++;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("aplicacao_importador_origem.write");
        const tblAplicacaoImportadorOrigem = new Table(
          "aplicacao_importador_origem"
        );

        const itensDesativados = this.currentRegioesOrigem
          .filter(
            (item) =>
              !this.dataAppImporterOrigin.regioes_origem.includes(
                item.regiao_origem
              )
          )
          .map((regiaoOrigem) => ({
            id_aplicacao: this.dataAppImporterOrigin.id_aplicacao,
            id_pessoa: this.dataAppImporterOrigin.id_pessoa,
            id_centro_custo: this.dataAppImporterOrigin.id_centro_custo,
            auth_key: this.dataAppImporterOrigin.auth_key,
            auto_fechamento: this.dataAppImporterOrigin.auto_fechamento,
            regiao_origem: regiaoOrigem.regiao_origem,
            ndatabase: regiaoOrigem.ndatabase,
            nregiao: regiaoOrigem.nregiao,
            desativado: "1",
          }));

        const itensNovos = this.dataAppImporterOrigin.regioes_origem
          .filter(
            (regiao_origem) =>
              !itensDesativados.some(
                (item) => item.regiao_origem === regiao_origem
              )
          )
          .map((regiao_origem) => {
            const regiaoOrigem = this.regioesOrigem.find(
              (item) => item.empresa_id === regiao_origem
            );

            return {
              id_aplicacao: this.dataAppImporterOrigin.id_aplicacao,
              id_pessoa: this.dataAppImporterOrigin.id_pessoa,
              id_centro_custo: this.dataAppImporterOrigin.id_centro_custo,
              auth_key: this.dataAppImporterOrigin.auth_key,
              auto_fechamento: this.dataAppImporterOrigin.auto_fechamento,
              regiao_origem,
              ndatabase: regiaoOrigem?.ndatabase ?? regiao_origem,
              nregiao: regiaoOrigem?.empresa_nome ?? regiao_origem,
              desativado: "0",
            };
          });

        const items = [...itensDesativados, ...itensNovos];

        if (items.length > 0) {
          tblAplicacaoImportadorOrigem.setColsFromObject(items[0]);
          tblAplicacaoImportadorOrigem.addRowsFromObject(...items);
        }

        rq.addTable(tblAplicacaoImportadorOrigem);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form.validate();
          else if (errorName === "ErrDupEntry")
            this.ret_msg = "Já existe um importador com esses dados";
          return;
        }

        this.ret_msg = "Origem de importação atualizada com sucesso";

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.pendingConnections--;
      }
    },

    clearForm() {
      this.dataAppImporterOrigin = {
        id_aplicacao: "",
        id_pessoa: "",
        id_centro_custo: "",
        auth_key: "",
        auto_fechamento: "1",
        regioes_origem: [],
      };

      this.currentRegioesOrigem = [];

      this.form.resetValidation();
    },

    async getApps() {
      try {
        this.pendingConnections++;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("aplicacao.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.apps = rsp.getTable("aplicacao")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.pendingConnections--;
      }
    },

    async getPeople() {
      try {
        this.pendingConnections++;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas.read", {
          tipo: "EMPRESA",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.empresas = rsp.getTable("pessoas")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.pendingConnections--;
      }
    },

    async getCostCenters() {
      try {
        this.pendingConnections++;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("centro_de_custo.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.costCenters = rsp.getTable("centro_custo")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.pendingConnections--;
      }
    },

    async getOrigins() {
      try {
        this.pendingConnections++;
        this.ret_id = 1;
        this.ret_msg = "";

        if (!this.dataAppImporterOrigin.id_aplicacao) return;

        const rq = new Rq("api_importer.Regiao", {
          idAplicacao: this.dataAppImporterOrigin.id_aplicacao,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.regioesOrigem = rsp.getTable("regiao")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.pendingConnections--;
      }
    },

    async getAppImportersOrigins() {
      try {
        this.pendingConnections++;
        this.ret_id = 1;
        this.ret_msg = "";

        if (
          !this.dataAppImporterOrigin.id_aplicacao ||
          !this.dataAppImporterOrigin.id_pessoa ||
          !this.dataAppImporterOrigin.id_centro_custo
        )
          return;

        const rq = new Rq("aplicacao_importador_origem.read", {
          idAplicacao: this.dataAppImporterOrigin.id_aplicacao,
          idPessoa: this.dataAppImporterOrigin.id_pessoa,
          idCentroCusto: this.dataAppImporterOrigin.id_centro_custo,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          this.currentRegioesOrigem = [];
          this.dataAppImporterOrigin.regioes_origem = [];
          return;
        }

        this.currentRegioesOrigem =
          rsp.getTable("aplicacao_importador_origem")?.getRowsObject() ?? [];

        this.dataAppImporterOrigin.regioes_origem =
          this.currentRegioesOrigem.map((item) => item.regiao_origem);
      } catch (error) {
        console.error(error);
      } finally {
        this.pendingConnections--;
      }
    },
  },
});
